import React, { useEffect, useState } from "react"
import CardPuntoDeVenta from "../hayPromoHayMundial/CardPdv";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import { IsMobileDevice } from "../../../utils/ismobile"
// import 'swiper/swiper.scss';
// require("../../styles/hayPromoHayMundial/slidePdv.module.css");
// import 'swiper/modules/pagination/pagination.min.css';

export default function SliderPuntoDeVenta({ pdvs, setZoom, setPosition }) {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
      setIsMobile(IsMobileDevice());
    }, [])
    return (
        <>
            <Swiper
                modules={[Pagination]}
                spaceBetween={90}
                slidesPerView={isMobile ? 2 : 4}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
            >
            {pdvs.length > 0 && 
                (pdvs.map(pdv=>{
                    return(
                        <SwiperSlide>
                            <CardPuntoDeVenta 
                                pdv={pdv}
                                setZoom={setZoom}
                                setPosition={setPosition}
                            />
                        </SwiperSlide>
                    )
                })
            )}
            </Swiper> 
        </>    
    )
    
}