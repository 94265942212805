import React from "react"
import styles from "../../styles/quilmesrock/footer.module.css"
import logoQ from "../../images/logos/quilmesLogo.svg"
import logoIg from "../../images/logos/logoIg.svg"
import logoFb from "../../images/logos/logoFb.svg"
import logoTw from "../../images/logos/logoTw.svg"
import logoYou from "../../images/logos/logoYou.svg"
import { Container, Row } from "react-bootstrap"
import * as Scroll from "react-scroll"

let scroll = Scroll.animateScroll
const Footer = () => {
  function handleClick() {
    scroll.scrollToTop()
  }

  return (
    <footer className={`bottom  ${styles.footer_container}`}>
      <img
        onClick={handleClick}
        src={logoQ}
        alt="logo"
        className={styles.logoQ}
      />

      <div className={styles.footer_menu}>
        <div id={styles.footerOptions}>
          <a
            target="_blank"
            href="https://www.quilmes.com.ar/politicadeprivacidad.pdf"
            className={styles.links}
          >
            <p> POLÍTICA DE PRIVACIDAD</p>
          </a>
          <p> | </p>
          <a
            target="_blank"
            href=" https://www.quilmes.com.ar/bases_y_condiciones.pdf"
            className={styles.links}
          >
            <p> BASES Y CONDICIONES</p>
          </a>
          <p id={styles.pHidden}> | </p>
          <a
            target="_blank"
            href="https://www.quilmes.com.ar/terminosycondiciones.pdf"
            className={styles.links}
            id={styles.pHidden}
          >
            <p>TÉRMINOS LEGALES</p>
          </a>
          <p id={styles.pHidden}> | </p>
          <a
            target="_blank"
            href="https://www.cerveceriaymalteriaquilmes.com/carrera/"
            className={styles.links}
            id={styles.pHidden}
          >
            <p>OPORTUNUDADES LABORALES</p>
          </a>
          <p id={styles.pHidden}> | </p>
          <a
            target="_blank"
            href="https://www.tapintoyourbeer.com/"
            className={styles.links}
            id={styles.pHidden}>
            <p>TAP INTO YOUR BEER</p>
          </a>
        </div>
        <Container fluid className="d-block d-md-none mt-3">
          <Row className="ml-4 pl-1 justify-content-center align-item-center">
            <a
              target="_blank"
              href="https://www.quilmes.com.ar/terminosycondiciones.pdf"
              className={styles.links}
            >
              <p>TÉRMINOS LEGALES</p>
            </a>
            <p> | </p>
            <a
              target="_blank"
              href="https://www.cerveceriaymalteriaquilmes.com/carrera"
              className={styles.links}
            >
              <p>OPORTUNUDADES LABORALES</p>
            </a>
          </Row>
        </Container>
        <Container fluid className="d-block d-md-none mt-3">
          <Row className="justify-content-center align-item-center">
            <a
              target="_blank"
              href=" https://www.tapintoyourbeer.com/"
              className={styles.links}>
              <p>TAP INTO YOUR BEER</p>
            </a>
          </Row>
        </Container>
        <span>
          BEBER CON MODERACIÓN.PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO
          COMPARTA EL CONTENIDO CON MENORES. Quilmes®
        </span>
      </div>
      <div className={styles.socialContainer}>
        <a
          target="_blank"
          href="https://www.facebook.com/quilmescerveza"
        >
          <img id={styles.facebook} src={logoFb} />
        </a>
        <a target="_blank" href="https://twitter.com/Quilmes_Cerveza">
          <img className={styles.social} src={logoTw} />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/cervezaquilmes/"
        >
          <img className={styles.social} src={logoIg} />
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/c/quilmescerveza"
        >
          <img className={styles.social} src={logoYou} />
        </a>
      </div>
      {/*  */}
    </footer>
  )
}
export default Footer
